import { Component } from "react";
import React from "react";

class Input extends Component {
  state = {
    text: this.props.value,
  };

  onChange(e) {
    this.setState({ text: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ text: "" });
    this.props.onSendMessage(this.state.text);
  }

  render() {
    const { placeHolder, text, className } = this.props;
    const isJoin = className ? "" : "from1";
    return (
      <div className={`Input ${isJoin}`}>
        <form onSubmit={(e) => this.onSubmit(e)}>
          <input
            onChange={(e) => this.onChange(e)}
            value={this.state.text}
            type="text"
            placeholder={
              placeHolder ? placeHolder : "Enter your message and press ENTER"
            }
            autoFocus={true}
          />
          <button
            style={{
              opacity: this.state.text ? 1 : 0.5,
              backgroundColor: "grey",
            }}
            disabled={!this.state.text}>
            {text ? text : "Send"}
          </button>
        </form>
      </div>
    );
  }
}

export default Input;
