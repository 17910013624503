import { Component } from "react";
import React from "react";

class Messages extends Component {
  render() {
    const { messages } = this.props;
    return (
      <ul className="Messages-list">
        {messages.map((m) => this.renderMessage(m))}
      </ul>
    );
  }

  renderMessage(message) {
    const { member, text } = message;
    const key = member.clientData.message_id + text;
    const { currentMember } = this.props;
    const messageFromMe = member.clientData.id === currentMember.clientData.id;
    const className = messageFromMe
      ? "Messages-message currentMember"
      : "Messages-message";
    return (
      <li className={className} key={key}>
        <span
          className="avatar"
          style={{ backgroundColor: member.clientData.color }}>
          <div className="username">{member.clientData.username}</div>
        </span>
        <div className="Message-content">
          <div className="text">
            <pre>{text}</pre>
          </div>
        </div>
      </li>
    );
  }
}

export default Messages;
