import React, { useState, useRef, useEffect } from "react";
import io from "socket.io-client";
import "./App.css";
import Messages from "./Messages";
import Input from "./Input";

//set the Alert to show when the user disconnected
const Alert = ({ message, onClose }) => {
  return (
    <div className="alert">
      <div className="alert-content">
        <p>{message}</p>
        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

const App = ({ route }) => {
  const peerRef = useRef();
  const socketRef = useRef();
  const otherUser = useRef();
  const sendChannel = useRef();
  const [roomId, setRoomId] = useState();
  const [connected, setConnected] = useState(false);

  const [device, setDevice] = useState(null);
  const [user, setUser] = useState("");
  const [counter, setCounter] = useState(0);
  const [isFirst, setIsFirst] = useState(false);
  const [member, setMember] = useState({
    clientData: {
      message_id: Math.random(1000).toString(),
      username: "Me",
      id: 1,
      color: "rgb(209, 209, 209)",
    },
  });
  const [messages, setMessages] = useState([]);
  const [line, setLine] = useState("Waiting for user...");
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [otherUserID, setOtherUserID] = useState(null);
  const [serviceType, setServiceType] = useState("call");
  const [showAlert, setShowAlert] = useState(false);

  const DEFAULT_ROOM_ID = "helpon_robert_nexstep_com";
  const initialRoomId = DEFAULT_ROOM_ID;

  var serviceT = "call";
  var initiator = true;

  function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  function connect(initialRoomId) {
    console.debug("[DEBUG] Now connecting...");
    let baseUrl = `${window.location.protocol}//${window.location.hostname}:9000`;
    socketRef.current = io.connect(baseUrl);
    socketRef.current.on("connect", () => {
      console.info(
        "[INFO] Connected successfully, joining support room",
        initialRoomId
      );
      if (!hasGetUserMedia()) {
        alert("getUserMedia() is not supported by your browser");
      }
      socketRef.current.emit("join room", initialRoomId);
    });

    socketRef.current.on("other user", (userID, service) => {
      setServiceType(service);
      serviceT = service;
      otherUser.current = userID;
      setOtherUserID(userID);
      otherUserJoined(userID);
      initiator = false;
    });

    socketRef.current.on("user joined", (userID, service) => {
      console.log("[INFO] User joined", userID, service);
      setServiceType(service);
      serviceT = service;
      otherUser.current = userID;
      setOtherUserID(userID);
      //need to check if the user called before I join or the otherwise
      initiator = true;
    });

    socketRef.current.on("offer", handleOffer);
    socketRef.current.on("answer", handleAnswer);
    socketRef.current.on("ice-candidate", handleNewICECandidateMsg);
    socketRef.current.on("user-disconnected", handleUserDisconnected);
  }

  function otherUserJoined(userID) {
    setConnected(true);
    setLine("User requested support session, joining now...");
    callUser(userID);
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const initialRoomId = urlParams.get("room") || DEFAULT_ROOM_ID;
    connect(initialRoomId);
    return () => {};
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!initiator) {
        //I joined the room after the user call, should reload and rejoin
        hangup();
      }
    }, 7000);
    return () => clearTimeout(timeoutId);
  }, [device]);

  function callUser(userID) {
    // This will initiate the call
    console.info("[INFO] Initiate a call to", userID);
    peerRef.current = Peer(userID);

    //create the initiator sending channel
    sendChannel.current = peerRef.current.createDataChannel("sendChannel");
    sendChannel.current.onopen = function (event) {
      console.log(
        "[SUCCESS] Data channel connection established, waiting for the first message"
      );
    };
    sendChannel.current.onclose = function (event) {
      // console.debug('[DEBUG] send channel closed');
    };

    // listen to incoming messages
    sendChannel.current.onmessage = handleReceiveMessage;

    setTimeout(() => {
      peerRef.current.onnegotiationneeded = () =>
        handleNegotiationNeededEvent(userID);
    }, 1000);
  }

  function Peer(userID) {
    if (serviceT === "call") {
      //Audio stream is not necessary in live chat
      initLocalStream();
    }
    //create the WebRTC peer connection
    const peer = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });
    peer.onicecandidate = handleICECandidateEvent;
    peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);
    return peer;
  }

  async function initLocalStream() {
    console.log("[INFO] initial streams");

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        // Filter for audio output devices (speakers)
        const speakerDevices = devices.filter(
          (device) => device.kind === "audiooutput"
        );
        // Obtain the deviceId of the desired speaker
        const desiredSpeakerDeviceId = speakerDevices[0].deviceId;
        navigator.mediaDevices
          .getUserMedia({
            audio: { output: { deviceId: desiredSpeakerDeviceId } },
          })
          .then((stream) => {
            if (peerRef.current) {
              stream
                .getTracks()
                .forEach((track) => peerRef.current.addTrack(track, stream));
              console.debug(
                "[DEBUG] added",
                stream.getTracks().length,
                "media tracks to peer connection"
              );

              // add the remote track from the connected participant.
              const remoteMediaStream = new MediaStream();
              peerRef.current.addEventListener("track", (event) => {
                event.streams[0].getTracks().forEach((track) => {
                  remoteMediaStream.addTrack(track);
                });
                setRemoteStream(remoteMediaStream);

                //play the remote stream on the device speaker
                const audio = new Audio();
                audio.srcObject = remoteMediaStream;
                audio.play();
              });
            } else {
              console.warn(
                "[WARN] No peer connection available to add the media tracks!"
              );
            }
            setLocalStream(stream);
          })
          .catch((error) => {
            // Handle permission or other errors
            console.error(error);
          });
      })
      .catch((error) => {
        console.error("Error enumerating devices:", error);
      });
  }

  function handleNegotiationNeededEvent(userID) {
    // Make Offer to other participant after joining the connection
    console.log("[INFO] Negotiation Needed Event");
    peerRef.current
      .createOffer()
      .then((offer) => {
        return peerRef.current.setLocalDescription(offer);
      })
      .then(() => {
        const payload = {
          target: userID,
          caller: socketRef.current.id,
          sdp: peerRef.current.localDescription,
        };
        socketRef.current.emit("offer", payload);
      })
      .catch((err) =>
        console.error("Error handling negotiation needed event", err)
      );
  }

  function handleOffer(incoming) {
    // Handle Offer made by the initiating peer
    console.info("[INFO] Handling Offer, i started the call");
    setServiceType(serviceT);
    setConnected(true);
    if (peerRef.current === null || peerRef.current === undefined) {
      peerRef.current = Peer();
    } else {
      console.debug(
        "[DEBUG] Peer connection already established, handling offer without creating a new one.",
        peerRef.current
      );
    }

    peerRef.current.ondatachannel = (event) => {
      sendChannel.current = event.channel;

      sendChannel.current.onopen = function (event) {
        console.debug(
          "[DEBUG] send channel opened, could start sending messages"
        );
      };
      sendChannel.current.onclose = function (event) {
        console.log("send channel closed");
      };
      sendChannel.current.onmessage = handleReceiveMessage;

      console.info("[SUCCESS] Data Connection established");
    };

    const desc = new RTCSessionDescription(incoming.sdp);
    peerRef.current
      .setRemoteDescription(desc)
      .then(() => {})
      .then(() => {
        return peerRef.current.createAnswer();
      })
      .then((answer) => {
        return peerRef.current.setLocalDescription(answer);
      })
      .then(() => {
        const payload = {
          target: incoming.caller,
          caller: socketRef.current.id,
          sdp: peerRef.current.localDescription,
        };
        socketRef.current.emit("answer", payload);
      });
  }

  function handleAnswer(message) {
    // Handle answer by the remote peer
    console.info("[INFO] Handling Offer answer, user started the call");
    setConnected(true);
    const desc = new RTCSessionDescription(message.sdp);
    peerRef.current
      .setRemoteDescription(desc)
      .catch((e) => console.log("Error handle answer", e));
  }

  function handleReceiveMessage(e) {
    console.log("[INFO] Message received from peer", e.data);
    const msg = [
      {
        _id: Math.random(1000).toString(),
        text: JSON.stringify(e.data, null, 2),
        createdAt: new Date(),
        user: {
          _id: 2,
        },
      },
    ];
    console.debug("[DEBUG] original message object", msg);

    let clientData = {
      message_id: Math.random(1000).toString(),
      username: "User",
      id: 2,
      color: "rgb(39, 86, 158)",
    };
    let newMsg = {
      member: { clientData },
      text: e.data,
    };

    if (newMsg.text.includes("@nexstep.com")) {
      setIsFirst(true);
      const msgObj = JSON.parse(newMsg.text);
      setUser(msgObj.User);
      setDevice(msgObj.Device);
    } else {
      setMessages((current_messages) => [...current_messages, newMsg]);
    }

    setTimeout(() => {
      setCounter(1);
    }, 50);
  }

  function handleICECandidateEvent(e) {
    console.log("ice candidate event, setting remote stream", e);
    if (e.candidate) {
      const payload = {
        target: otherUser.current,
        candidate: e.candidate,
      };
      socketRef.current.emit("ice-candidate", payload);
    }
  }

  function handleNewICECandidateMsg(incoming) {
    console.debug("[DEBUG] ice candidate message", incoming);
    const candidate = new RTCIceCandidate(incoming);
    if (peerRef.current) {
      peerRef.current.addIceCandidate(candidate).catch((e) => console.error(e));
    } else {
      console.warn("[WARN] No peer connection available to add ice candidate!");
    }
  }

  function onSendMessage(message) {
    if (sendChannel.current.readyState === "open") {
      sendChannel.current.send(message);
      let memberNewData = member;
      memberNewData.clientData.message_id = Math.random(1000).toString();
      setMember(memberNewData);
      let newMsg = { member: member, text: message };
      setMessages((current_messages) => [...current_messages, newMsg]);
    } else {
      console.warn("[WARN] data channel not open, cannot send message");
    }
  }

  function hangup() {
    if (serviceType !== "chat" && serviceType != null) {
      if (localStream !== null) {
        //Stopping all tracks
        localStream.getTracks().forEach((track) => track.stop());
        setLocalStream(null);
        setRemoteStream(null);
      } else {
        console.warn("[WARN] No local stream found, cannot stop tracks.");
      }
    }

    if (peerRef.current) {
      //closing data channel
      sendChannel.current.close();
      sendChannel.current = null;
      //closing peer connection
      peerRef.current.close();
      peerRef.current = undefined;
      socketRef.current.disconnect();
      socketRef.current.close();
      setConnected(false);
      setRoomId(null);
      setDevice(null);
      setUser("");
      setMessages([]);
      setLine("Waiting for user...");
      console.info("[SUCCESS] Connection closed");
    }
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }
  const handleUserDisconnected = () => {
    //don't show the Alert if the user joined before me
    if (initiator) {
      setShowAlert(true);
      hangup();
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  return (
    <div className="App">
      {showAlert && (
        <div className="alert-overlay">
          <Alert
            message="The user has been disconnected."
            onClose={handleCloseAlert}
          />
        </div>
      )}
      {!connected ? (
        <div className="App-header1" style={{ marginTop: 100 }}>
          <img
            style={{ width: 150, height: 100, marginBottom: 50 }}
            src="NexStepLogo.png"
            alt="NexStep logo"
          />
          <br />
          <br />
          <img src="spinner.gif" alt="waiting spinner" />
          <br />
          <br />
          <br />
          {line}
          <br />
        </div>
      ) : (
        <div className="App">
          <div className="App-header">
            <img
              style={{ width: 120, height: 80 }}
              src="NexStepLogo.png"
              alt="NexStep logo"
            />
            <br />

            <button
              style={{
                position: "fixed",
                right: 20,
                top: 20,
                backgroundColor: "transparent",
              }}
              onClick={hangup}>
              <img style={{ width: 40 }} src="hangup-icon.png" alt="hangup" />
            </button>

            {user != null && user.trim().length > 0 && (
              <div
                style={{
                  padding: 10,
                  margin: 10,
                  width: "43%",
                  border: "1px solid grey",
                  float: "left",
                }}>
                <b>USER</b>
                <br />
                {user}
              </div>
            )}
            {device != null && device.Name && (
              <div
                style={{
                  border: "1px solid grey",
                  padding: 10,
                  margin: 10,
                  float: "left",
                  width: "43%",
                }}>
                <b>DEVICE</b>
                <br />
                {device.Name}
                <br />
                Company {device.Company}
                <br />
                Model {device.Model}
                <br />
                Serial {device.Serial}
                <br />
                Purchased: {device.Purchased}
                <br />
                Warranty: {device.Warranty}
                <br />
                Last Support: {device.Last_Support}
                <br />
              </div>
            )}
            {(user === null || user.trim().length === 0) && (
              <div>
                <br />
                <img
                  style={{ width: 50 }}
                  src="spinner.gif"
                  alt="waiting spinner"
                />
                <br />
                Awaiting user data...
              </div>
            )}
          </div>
          {isFirst && (
            <div>
              <Messages
                messages={messages}
                currentMember={member}
                counter={counter}
              />
              <Input
                value={messages}
                onSendMessage={(message) => onSendMessage(message)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
